import MessageListItem from '../components/MessageListItem';
import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToggle,
  IonToolbar,
  IonVirtualScroll,
  useIonViewWillEnter
} from '@ionic/react';
import './Home.css';

// import { Virtuoso } from 'react-virtuoso';

import salesman from '../salesman.js'
import { RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import {calculateroute } from '../counterSlice';
// import { GoogleMap } from '@capacitor/google-maps';
import { useRef } from 'react';
// import { CapacitorGoogleMaps } from '@capacitor-community/capacitor-googlemaps-native';
// import { LatLng } from '@capacitor-community/capacitor-googlemaps-native/dist/esm/types/common/latlng.interface';
// import { CapacitorGoogleMaps, LatLng } from "@capacitor-community/google-maps";

// import GoogleMapReact from 'google-map-react';
// import { Map, InfoWindow, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';
// import { MapViewWithHeading, ArrowedPolyline } from 'react-native-maps-line-arrow';
import Mapit from "../components/Map"

// import zipCodes from "../zipcodes.json"
// import airports from "../data/airports.json"
import companies from "../data/companies.json"
import { playCircle, radio, library, search } from 'ionicons/icons';
interface ContainerProps {
  text: string;
  lat: number
  lng: number
}
const AnyReactComponent: React.FC<ContainerProps> = ({ text, lat, lng }) => <div>{text}</div>;

const Home: React.FC = () => {

  const [messages, setMessages] = useState<Message[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const companyList = useSelector((state: RootState) => state.site.companies)
  const dispatch = useDispatch()
  const defaultProps = {
    center: {
      lat: 34.019454,
      lng: -118.491191
    },
    zoom: 5
  };
  const initializeMap = async () => {
    const element: any = document.getElementById("container");
    //element.style.background = "";
    // afterwards get its boundaries like so:
    const boundingRect = element.getBoundingClientRect();
    //alert(boundingRect)
    // first of all, you should initialize the Maps SDK:
    // let result = await CapacitorGoogleMaps.initialize({
    //   key: "AIzaSyBrhFu-HtcCZ5Duv0Ul4dIYAMKsOWY8ue8",
    //   devicePixelRatio: window.devicePixelRatio, // this line is very important
    // });
    // alert(result)
    // then get the element you want to attach the Maps instance to:

    // we can now create the map using the boundaries of #container
    // try {
    //   const result = await CapacitorGoogleMaps.createMap({
    //     cameraPosition: {},
    //     boundingRect: {
    //       width: Math.round(boundingRect.width),
    //       height: Math.round(boundingRect.height),
    //       x: Math.round(boundingRect.x),
    //       y: Math.round(boundingRect.y),
    //     }
    //   });

    // remove background, so map can be seen
    // (you can read more about this in the "Setting up the WebView" guide)


    // finally set `data-maps-id` attribute for delegating touch events
    //   element.setAttribute("data-maps-id", result.googleMap.mapId);

    //   alert("Map loaded successfully");
    // } catch (e) {
    //   alert("Map failed to load");
    // }
  };
  useIonViewWillEnter(async () => {
    const msgs = getMessages();

    //initializeMap()
    // await createMap()
    //newMap.addMarker([3,2])
    // for (let x: number = 0; msgs.length; x++) {
    //   // const markerId = await newMap.addMarker({
    //   //   coordinate: {
    //   //     lat: msgs[x].point[0],
    //   //     lng:  msgs[x].point[1]
    //   //   }
    //   // });
    //   //CapacitorGoogleMaps.addPolyline()
    // }
    // const pts: LatLng[] = [
    //   {
    //     latitude: -64.238,
    //     longitude: -56.631,
    //   },
    //   {
    //     latitude: 36.34,
    //     longitude: -97.916,
    //   }
    // ];

    // let line = await CapacitorGoogleMaps.addPolyline({
    //   points:pts,
    //   color: '#ff00ff',
    //   width: 2
    // });
    // // newMap.addMarker(line)

    //someCoords.push()
    var points: any = [
      // new salesman.Point(2, 3)
      // new salesman.Point(-64.238, -56.631),
      // new salesman.Point( 36.34, -97.916),
      //new salesman.Point( 34.019454,  -118.491191)
      //other points
    ];
    //let z: any = zipCodes;
    /* for later
    for(let x = 4; x<23;x++){
      //let coords:any = {lat:z[x].latitude,lng:z[x].longitude};
      //someCoords.push(coords)
      let coords:any = {lat:z[x].latitude,lng:z[x].longitude};
      points.push( new salesman.Point( z[x].latitude, z[x].longitude,z[x]))
    }

   
    var solution = salesman.solve(points,.999999);
    var ordered_points:any = solution.map((i: any) => points[i]);
    console.log(ordered_points)
    for(let x =0;x<ordered_points.length;x++){
      let c:any = {lat:ordered_points[x].x,lng:ordered_points[x].y,item:ordered_points[x].item};
      someCoords.push(c)
    }
    */
    //let l:any = zipCodes[3]

    //console.log(someCoords)
    //let hotspot = LatLng() 
    let locs = [];
    let apts: any[] = Object.entries(companyList);
    //let apts1: any = airports
    // let a: any = Object.assign({}, apts1["KVNY"])
    // locs.push(Object.assign({}, apts1["KVNY"]))
    // locs.push(Object.assign({}, apts1["KBUR"]))
    for (let x = 0; x < apts.length; x++) {
      //console.log(apts[x][1])
      //a = apts[x][1]
      locs.push(apts[x][1])
    }
    //locs.push(a)
    //console.log("locs", locs)

    setLocations(getPoints(locs));//someCoords)
    //console.log(ordered_points)
    setMessages(msgs);
  });

  const refresh = (e: CustomEvent) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };
  // const mapRef = useRef<HTMLElement>();
  // let newMap: GoogleMap;
  const getPoints = (locs: any) => {
    // return locs;
    var points: any = [
      // new salesman.Point(2, 3)
      // new salesman.Point(-64.238, -56.631),
      // new salesman.Point( 36.34, -97.916),
      //new salesman.Point( 34.019454,  -118.491191)
      //other points
    ];
    for (let x = 0; x < locs.length; x++) {
      points.push(new salesman.Location(locs[x]))
    }
    var solution = salesman.solve(points, .999999);
    var ordered_points: any = solution.map((i: any) => points[i].location);
    //console.log(ordered_points)
    //console.log(ordered_points)
    return ordered_points;
    // for(let x =0;x<ordered_points.length;x++){
    //   let c:any = {lat:ordered_points[x].x,lng:ordered_points[x].y,item:ordered_points[x].item};
    //   someCoords.push(c)
    // }
  }
  // async function createMap() {
  //   if (!mapRef.current) return;

  //   newMap = await GoogleMap.create({
  //     id: 'my-cool-map',
  //     element: mapRef.current,
  //     apiKey: "AIzaSyBrhFu-HtcCZ5Duv0Ul4dIYAMKsOWY8ue8",
  //     //apiKey: process.env.REACT_APP_YOUR_API_KEY_HERE,
  //     config: {
  //       center: {
  //         lat: 33.6,
  //         lng: -117.9
  //       },
  //       zoom: 2
  //     }
  //   })
  // }
  // let someCoords:any = [
  //   // { lat: 34.019454, lng: -118.491191 },
  //   // { lat: 34, lng: -118 }
  // ];
  const calc = async (i:any) =>{
    //setInterval(()=>{dispatch(calculateroute())},10000)
    //dispatch(calculateroute())
    dispatch(calculateroute())
  }
  return (
    <IonPage id="home-page">
      {/* <IonHeader>
        <IonToolbar>
          <IonTitle> Route Optimizer */}
            {/* <button onClick={calc}>Calculate Routes</button> */}
            {/* <IonToggle onClick={()=> setTimeout((i)=>{calc(i)},0)} /> */}
            {/* </IonTitle>
        </IonToolbar>
      </IonHeader> */}
      <IonContent fullscreen>
        {/* <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              Routes
            </IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <IonContent style={{ height: 'calc(75vh - 100px)', width: '100%' }}>
          <div style={{ height: 'calc(75vh - 100px)', width: '100%' }}>
            <Mapit {...companyList} />
          </div>
        </IonContent>
        <IonContent style={{  height: 'calc(30vh + 40px)', width: '100%' }}>
          <IonList>
            {companies.map((m, k) => <MessageListItem key={k} message={m} />)}
          </IonList>
        </IonContent>
       
       
        {/* <IonVirtualScroll  style={{ height: 'calc(50vh )', width: '100%' }}> */}
        {/* <IonList > */}
        {/* {messages.map(m => <MessageListItem key={m.id} message={m} />)} */}
        {/* <Virtuoso
          style={{ height: 'calc(50vh )', width: '100%' }}
          totalCount={companies.length}
          itemContent={(index) => {
            return (
              <div style={{ height: '56px' }}>
                {companies.map((m, k) => <MessageListItem key={k} message={m} />)}
              </div>
            );
          }}
        /> */}
        {/* </IonList> */}
        {/* </IonVirtualScroll> */}
        {/* <div className="component-wrapper">
          <div id="container"></div>
          <capacitor-google-map ref={mapRef} style={{
            display: 'inline-block',
            width: "100%",
            height: 768
          }}></capacitor-google-map>

          <button onClick={createMap}>Create Map</button>
        </div> */}
        {/* <IonList>
          {messages.map(m => <MessageListItem key={m.id} message={m} />)}
        </IonList> */}
        {/* google={this.props.google} */}
        {/* <Map  zoom={14}>

          <Marker onClick={this.onMarkerClick}
            name={'Current location'} />

          <InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1>{this.state.selectedPlace.name}</h1>
            </div>
          </InfoWindow>
        </Map> */}
        {/* <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyA2gTVViuFF0qXuAQNnX_EkoNmjZv4X1Mg" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <Polyline
              path={someCoords}
             
                options={{ 
                  strokeColor: '#00ffff',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  icons: [{ 
                    icon: "hello",
                    offset: '0',
                    repeat: '10px'
                  }],
                }} />
            <AnyReactComponent
              lat={34.019454}
              lng={-118.491191}
              text="My Marker"
              
            />
          </GoogleMapReact>
        </div> */}
      </IonContent>
    
    </IonPage >
  );
};

export default Home;


/*
I am looking math genius to solve this traveling salesman problem for us

1st problem: jet trips
I have a spreadsheet of companies with addresses and I have  value points score for each account. 
I need an algorithm Wich will create list of possible trips order by value in points devided 
by fly hour. 
Each trip should be 3-12 days. I travel by private plane from van nyce and paying for hours.
 
2nd problem car trips:
I have a spreadsheet of companies with addresses and I have value points score for each account.
 
I need an algorithm Wich will create all possible road trips starting at airports.
Road trips should be 5-14 days long and stop at night at a 4-star hotel.
Each meeting is 3 hours.
 
Sort  and optimise by points/driving distance.
https://turfjs.org/docs/#nearestPoint
https://github.com/reduxjs/redux/issues/557
https://rawgit.com/techfort/LokiJS/master/examples/sandbox/LokiSandbox.htm
https://rxdb.info/
https://github.com/louischatriot/nedb
https://npmtrends.com/forerunnerdb-vs-lokijs-vs-nedb-vs-rxdb-vs-sqlite3
*/