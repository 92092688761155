import { createSlice, configureStore } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


import salesman from './salesman.js'

const counterSlice = createSlice({
  name: 'companies',
  initialState: {
    companies: [
    //   {
    //     "Employbl Company ID": 0,
    //     "icao": "KVNY",
    //     "iata": "",
    //     "name": "Van Nuys Airport",
    //     "city": "Van Nuys",
    //     "state": "California",
    //     "country": "US",
    //     "elevation": 802,
    //     "lat": 34.2098007202,
    //     "lng": -118.4899978638,
    //     "tz": "America\/Los_Angeles"
    // }
      // {
      //   "Employbl Company ID": 1,
      //   "Company Name": "3scale",
      //   "Website": "3scale.net",
      //   "Address 1": "450 Townsend St #204",
      //   "City": "San Francisco",
      //   "State": "CA",
      //   "Zip": 94107,
      //   "lat": 37.774634,
      //   "lng": -122.398642,
      //   "Company Description": "Unlock the power of your APIs (owned by RedHat)",
      //   "Thumbnail URL": ""
      // },
    ]
  },
  reducers: {
    incremented: (state:any, action: any) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      //state.value += 1
      state.companies.push( action.payload);
      // var points: any = [ ];
      // for (let x = 0; x <  state.companies.length; x++) {
      //   points.push(new salesman.Location(state.companies[x]))
      // }
      // var solution = salesman.solve(points, .9999999);
      // var ordered_points: any = solution.map((i: any) => points[i].location);
      // state.companies =ordered_points;
      //console.log(ordered_points)
      //console.log(ordered_points)
      //return ordered_points;
      console.log( action.payload)
    },
    decremented:  (state:any, action: any)  => {
      for(let x =0; x< state.companies.length;x++)
      if(state.companies[x]["Employbl Company ID"]===action.payload["Employbl Company ID"])
      state.companies.splice(x,1)
      // var points: any = [ ];
      // for (let x = 0; x <  state.companies.length; x++) {
      //   points.push(new salesman.Location(state.companies[x]))
      // }
      // var solution = salesman.solve(points, .9999999);
      // var ordered_points: any = solution.map((i: any) => points[i].location);
      // state.companies =ordered_points;
     // state.companies.push();// -= 1
    },
    calculateroute:  (state:any,)  => {
      // for(let x =0; x< state.companies.length;x++)
      // if(state.companies[x]["Employbl Company ID"]===action.payload["Employbl Company ID"])
      // state.companies.splice(x,1)
      var points: any = [ ];
      for (let x = 0; x <  state.companies.length; x++) {
        points.push(new salesman.Location(state.companies[x]))
      }
      // var solution = salesman.solve(points, .99999,(solution:any)=>{
      //   //console.log("calc ", i)
      //   state.companies = solution.map((i: any) => points[i].location);
      // });
      //var solution = salesman.solve(points, .99999,(solution:any)=>{
        //console.log("calc ", i)
        var solution = salesman.solve(points, .999)
        state.companies = solution.map((i: any) => points[i].location);
        //state.companies.push()
      //});
      // var ordered_points: any = solution.map((i: any) => points[i].location);
      // state.companies =JSON.parse(JSON.stringify(ordered_points));
      // solution = salesman.solve(state.companies, .99999);
      // ordered_points= solution.map((i: any) => points[i].location);
      // state.companies =JSON.parse(JSON.stringify(ordered_points));
      // solution = salesman.solve(state.companies, .9999999);
      // ordered_points = solution.map((i: any) => points[i].location);
      // state.companies =JSON.parse(JSON.stringify(ordered_points));
      console.log("calculated")
     // state.companies.push();// -= 1
    }
  }
})

export const { incremented, decremented,calculateroute } = counterSlice.actions

const store = configureStore({
  reducer: counterSlice.reducer
})

// Can still subscribe to the store
store.subscribe(() => console.log(store.getState()))

// Still pass action objects to `dispatch`, but they're created for us
// store.dispatch(incremented())
// // {value: 1}
// store.dispatch(incremented())
// // {value: 2}
// store.dispatch(decremented())
// {value: 1}
//export const {  incremented } = counterSlice.actions
export default counterSlice.reducer