import React from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
  HeatmapLayer,
  Data,
  MarkerClustererF,
  MarkerF,
} from '@react-google-maps/api'
import { calculateroute } from '../counterSlice'
import { useDispatch, useSelector } from 'react-redux';

const containerStyle = {
  width: '400px',
  height: '400px',
}
const lib =  ['visualization']
const center = {
  // lat: 34.019454, lng: -118.491191
  //lat: 18.269187, lng: -66.864993
  lat: 38,
  lng: -99,
}

function MyComponent(someCoords) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA2gTVViuFF0qXuAQNnX_EkoNmjZv4X1Mg',
    libraries:lib,
    //mapIds:"88a5131ec3008961",
    //mapId:"88a5131ec3008961"
    
  })
  //console.log(Object.assign([],someCoords))
  const [map, setMap] = React.useState(null)
  const [heatmapData, setHeatmap] = React.useState([])
  const dispatch = useDispatch()

  //let heatmapData = [];
  let c = Object.assign([], someCoords)
  console.log(JSON.stringify(c))

  if(map){
    
    let bounds = new window.google.maps.LatLngBounds()
    
    for (let x = 0; x < c.length; x++) {
        bounds.extend({lat:c[x].lat, lng:c[x].lng,})
        console.log("loaded", {lat:c[x].lat, lng:c[x].lng,})
        /*
        console.log(someCoords[x].lat)
        h.push({
          location: new window.google.maps.LatLng(
            someCoords[x].lat,
            someCoords[x].lng,
          ),
          weight: 1,
        })*/
      }
    //bounds.extend({lat:37.782, lng:-122.443})
    map.fitBounds(bounds,150); //https://developers.google.com/maps/documentation/javascript/reference/map#Map.fitBounds
    

    //console.log("loaded", bounds)
  }
  const onLoad = React.useCallback(function callback(map) {

    function createCenterControl(map) {
        const controlButton = document.createElement("button");
      
        // Set CSS for the control.
        controlButton.style.backgroundColor = "#fff";
        controlButton.style.border = "2px solid #fff";
        controlButton.style.borderRadius = "3px";
        controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlButton.style.color = "rgb(25,25,25)";
        controlButton.style.cursor = "pointer";
        controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
        controlButton.style.fontSize = "16px";
        controlButton.style.lineHeight = "38px";
        controlButton.style.margin = "8px 0 22px";
        controlButton.style.padding = "0 5px";
        controlButton.style.textAlign = "center";
        controlButton.textContent = "Optimize";
        controlButton.title = "Click to calculate the map";
        controlButton.type = "button";
        // Setup the click event listeners: simply set the map to Chicago.
        controlButton.addEventListener("click", () => {
          //map.setCenter(chicago);
          dispatch(calculateroute())
          //window.open("https://www.google.com/maps/dir/one@37.765153,-122.418618/two@37.765136,-122.419112")
         // https://www.google.com/maps/dir/Santa+Monica,+CA/Los+Angeles+International+Airport+(LAX),+1+World+Way,+Los+Angeles,+CA+90045/Marina+del+Rey,+CA/@33.9543078,-118.3715475,12z
          ///data=!4m20!4m19!1m5!1m1!1s0x80c2a4cec2910019:0xb4170ab5ff23f5ab!2m2!1d-118.4911912!2d34.0194543!1m5!1m1!1s0x80c2b0d213b24fb5:0x77a87b57698badf1!2m2!1d-118.40853!2d33.9415889!1m5!1m1!1s0x80c2b08af005b32d:0x9756dab19b0bce9e!2m2!1d-118.4517449!2d33.9802893!3e0?hl=en-US

        });
        return controlButton;
      }
      function createNavigatorControl(map) {
        const controlButton = document.createElement("button");
      
        // Set CSS for the control.
        controlButton.style.backgroundColor = "#fff";
        controlButton.style.border = "2px solid #fff";
        controlButton.style.borderRadius = "3px";
        controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlButton.style.color = "rgb(25,25,25)";
        controlButton.style.cursor = "pointer";
        controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
        controlButton.style.fontSize = "16px";
        controlButton.style.lineHeight = "38px";
        controlButton.style.margin = "8px 0 22px";
        controlButton.style.padding = "0 5px";
        controlButton.style.textAlign = "center";
        controlButton.textContent = "Navigate";
        controlButton.title = "Click to calculate the map";
        controlButton.type = "button";
        // Setup the click event listeners: simply set the map to Chicago.
        controlButton.addEventListener("click", () => {
          //map.setCenter(chicago);
          //dispatch(calculateroute())
          window.location = "http://maps.apple.com/?ll=37.765153,-122.418618";//"https://www.google.com/maps/dir/37.765153,-122.418618/37.765136,-122.419112")
          //window.open("https://www.google.com/maps/dir/one@37.765153,-122.418618/two@37.765136,-122.419112")
         // https://www.google.com/maps/dir/Santa+Monica,+CA/Los+Angeles+International+Airport+(LAX),+1+World+Way,+Los+Angeles,+CA+90045/Marina+del+Rey,+CA/@33.9543078,-118.3715475,12z
          ///data=!4m20!4m19!1m5!1m1!1s0x80c2a4cec2910019:0xb4170ab5ff23f5ab!2m2!1d-118.4911912!2d34.0194543!1m5!1m1!1s0x80c2b0d213b24fb5:0x77a87b57698badf1!2m2!1d-118.40853!2d33.9415889!1m5!1m1!1s0x80c2b08af005b32d:0x9756dab19b0bce9e!2m2!1d-118.4517449!2d33.9802893!3e0?hl=en-US
        //https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
        });
        return controlButton;
      }
      // Create the DIV to hold the control.
  const centerControlDiv = document.createElement("div");
  const navControlDiv = document.createElement("div");

  // Create the control.
  const centerControl = createCenterControl(map);

  // Append the control to the DIV.
  centerControlDiv.appendChild(centerControl);
  navControlDiv.appendChild(createNavigatorControl(map))
  map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);
  map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(navControlDiv);
//https://developers.google.com/maps/documentation/javascript/examples/control-positioning
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //const bounds = new window.google.maps.LatLngBounds(center)
    //map.fitBounds(bounds);
    let h = [
      //  {lat:37.765153,lng: -122.418618},
      //    new window.google.maps.LatLng(37.765153, -122.418618),
      //   new window.google.maps.LatLng(37.765136, -122.419112),
      //   new window.google.maps.LatLng(37.765129, -122.419378),
      //   new window.google.maps.LatLng(37.765119, -122.419481),
      //   new window.google.maps.LatLng(37.7651, -122.419852),
      {
        location: new window.google.maps.LatLng(37.782, -122.447),
        weight: 0.5,
      },
      new window.google.maps.LatLng(37.782, -122.445),
      { location: new window.google.maps.LatLng(37.782, -122.443), weight: 1 },
      { location: new window.google.maps.LatLng(37.782, -122.441), weight: 1 },
      { location: new window.google.maps.LatLng(37.782, -122.439), weight: 1 },
      new window.google.maps.LatLng(37.782, -122.437),
      {
        location: new window.google.maps.LatLng(37.782, -122.435),
        weight: 0.5,
      },

      { location: new window.google.maps.LatLng(37.785, -122.447), weight: 1 },
      { location: new window.google.maps.LatLng(37.785, -122.445), weight: 1 },
      new window.google.maps.LatLng(37.785, -122.443),
      {
        location: new window.google.maps.LatLng(37.785, -122.441),
        weight: 0.5,
      },
      new window.google.maps.LatLng(37.785, -122.439),
      { location: new window.google.maps.LatLng(37.785, -122.437), weight: 1 },
      { location: new window.google.maps.LatLng(37.785, -122.435), weight: 1 },
    ]
    //console.log('someCoords', someCoords)

    for (let x = 0; x < someCoords.length; x++) {
      console.log(someCoords[x].lat)
      h.push({
        location: new window.google.maps.LatLng(
          someCoords[x].lat,
          someCoords[x].lng,
        ),
        weight: 1,
      })
    }
    setHeatmap(h)
    //map.mapIds = "88a5131ec3008961"
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  // const someCoords= [
  //     {lat: 34.019454, lng: -118.491191},
  //     {lat: 34, lng: -118}
  //   ];
  //console.log(Object.assign([],someCoords))
  //   console.log(Object.assign([],someCoords).length)
  let h = []
  let p = []
  //let c = []
  if (isLoaded) {
    //c = Object.assign([], someCoords)
    for (let x = 0; x < c.length; x++) {
      //console.log(Object.assign([],someCoords)[x].lat)
      h.push({
        location: new window.google.maps.LatLng(c[x].lat, c[x].lng),
        maxIntensity: 100,
        weight: 1,
      })
    }
    p = Object.assign(c);
    p.push(p[0])
    
  }
//   let bounds = new window.google.maps.LatLngBounds(center)
//   bounds.extend(someCoords)
  //map.fitBounds(bounds);
  return isLoaded ? (
    
    <GoogleMap
      mapContainerStyle={{ height: 'calc(75vh - 80px)', width: '100%' }}
      center={center}
      zoom={5}
      onLoad={onLoad}
      onUnmount={onUnmount}
      
      style={{ height: '100vh', width: '100%' }}
    //   mapId='8e0a97af9386fef'
       options={{ mapId: '88a5131ec3008961',
       mapTypeControl: false, 
       streetViewControl:false,
       fullscreenControl:false,
       zoomControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT,
      },
  
    }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {/* #FDAE10 */}
      <></>
      <Polyline options={{strokeColor:"blue"}}  path={p} />
      {/* <MarkerClustererF>
        {(clusterer) => 
         
               Object.assign([], someCoords).map((c, j) => {
                return <MarkerF  clusterer={clusterer} key={j} position={c} />
                //title={c.item.city}
              })
           
        }
      </MarkerClustererF> */}
      { c.map((c, j) => {
                return <MarkerF   key={j} position={c} />
                //title={c.item.city}
              })
            }
      <div></div>
      {/* {Object.assign([], someCoords).map((c, j) => {
        return <MarkerF key={j} position={c} />
        //title={c.item.city}
      })} */}
      {/* </MarkerClustererF> */}
      <HeatmapLayer
        onClick={() => {
          alert()
        }}
        options={{ radius: 50, dissipating: true }}
        data={h}
      />
      {/* <MarkerClusterer data={h}  /> */}
    </GoogleMap>
  ) : (
    <></>
  )
}

export default React.memo(MyComponent)
//https://developers.google.com/maps/documentation/javascript/heatmaplayer
//https://console.cloud.google.com/google/maps-apis/studio/styles/eb7c7f762e63752d/edit?project=second-abacus-350323