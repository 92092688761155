import { IonContent, IonItem, IonList } from "@ionic/react"
import states from "../data/states.json"
import airportsObject  from "../data/airports.json"
import citiesData  from "../data/cities.json"

import { useParams } from 'react-router';

const airports:any = Object.entries(airportsObject)
//console.log(airports)

const Page: React.FC = () => {
    const params = useParams<{ state: string, city: string }>();
    const cities:any = citiesData;
    const reducedAirports = airports.reduce((acc:any,obj:any)=>{
        //console.log(obj[1].country)
        if(obj[1].city === params?.city){
            acc.push(obj[1])
        }
        return acc
    },[])
    
    // const reducedCities = cities.reduce((acc:any,obj:any)=>{
    //     //console.log(obj[1].country)
    //     if(obj[1].city === params?.city){
    //         acc.push(obj[1])
    //     }
    //     return acc
    // },[])
    // console.log(params?.state)
    return (
        <>
            {params?.state ===undefined?
            <>
                <IonContent>
                    <IonList>
                        {states.map((s: any, k: number) => {
                            return (
                                <IonItem key={k} routerLink={"/search/" + s.name}>
                                    {s.name} 
                                </IonItem>
                            )
                        })
                        }
                    </IonList>
                </IonContent>
            </>:null}
            {params?.state !==undefined && params?.city ===undefined?
            <>
                <IonContent>
                    <IonList>
                        {cities[params?.state].map((s: any, k: number) => {
                            return (
                                <IonItem key={k} routerLink={"/search/" + params?.state +"/"+s}>
                                    
                                    {s}
                                </IonItem>
                            )
                        })
                        }
                    </IonList>
                </IonContent>
            </>:null}
            {params?.state !==undefined?
            <>
                <IonContent>
                    <IonList>
                        {reducedAirports.map((s: any, k: number) => {
                            return (
                                <IonItem key={k} routerLink={"/search/" + params?.state +"/"+params.city+"/" + s.icao}>
                                    
                                    {s.name}-  {s.city}
                                </IonItem>
                            )
                        })
                        }
                    </IonList>
                </IonContent>
            </>:null}
        </>
    )
}
export default Page